<template>
  <b-navbar class="main-navbar" toggleable="lg" type="dark" variant="primary">
    <b-container>
      <b-navbar-brand href="/#/">
        <img src="../assets/logo.webp" width="150"/>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <li class="nav-item">
            <router-link class="nav-link" to="/">Calculadora</router-link>
          </li>

          <li class="nav-item">
            <a class="nav-link" href="https://genialprev.curseduca.pro/" target="_blank">Treinamentos</a>
          </li>
          <!-- <li class="nav-item">
            <router-link id="employees-nav" class="nav-link" to="/online-classes">
             <i
              class="fa fa-lock"
              v-if="isOnFirstSevenDays"
              ></i>
             Video Aulas
            </router-link>
          </li> -->

          <!-- <li class="nav-item">
            <router-link id="employees-nav" class="nav-link" to="/mindmaps">
            <i class="fa fa-lock" v-if="isOnFirstSevenDays"></i>
             Mapas Mentais
            </router-link>
          </li> -->

          <li class="nav-item" v-if="isManagement">
            <router-link id="employees-nav" class="nav-link" to="/users">
              Usuários
            </router-link>
          </li>

          <li class="nav-item" v-if="isManagement">
            <span class="nav-link">
          |
            </span>
          </li>

          <li class="nav-item" v-if="isManagement">
            <router-link class="nav-link" to="/management/accounts">
              Contas
            </router-link>
          </li>

          <li class="nav-item" v-if="isManagement">
            <router-link class="nav-link" to="/management/indexes">
              Índices
            </router-link>
          </li>

        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto" id="account-nav">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template v-slot:button-content>
              <em>{{sessionUser.name}}</em>
            </template>
            <b-dropdown-item href="#" @click="myAccount">Minha conta</b-dropdown-item>
            <b-dropdown-item href="#" @click="logout">Sair</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NavBar',
  computed: {
    ...mapGetters(['sessionUser', 'isOnFirstSevenDays', 'isManagement'])
  },
  methods: {
    logout: function () {
      this.$store.dispatch('LOGOUT').then(() => {
        this.$router.push('/login');
      }).catch((e) => {
        console.info('Logout Error');
        console.error(e);
      });
    },
    myAccount: function () {
      this.$router.push(`/users/${this.sessionUser.id}/edit`).catch(err => {});
    }
  },
  mounted() {
    const intro = this.$intro();
    intro.setOptions({
      nextLabel: "Próximo",
      prevLabel: "Anterior",
      doneLabel: "Fechar",
      skipLabel: "Pular",
      steps: [{
        intro: 'Seja bem vindo ao teste de Luiz Carraro'
      }, {
        element: "#employees-nav",
        intro: 'Aqui está o controle de funcionários'
      }, {
        element: "#users-nav",
        intro: 'Aqui pode-se controlar os usuários de acesso a esta aplicação'
      }, {
        element: "#account-nav",
        intro: 'Aqui você pode trocar a sua senha ou sair da aplicação'
      }]
    });

    // intro.start();
  }
}
</script>

<style>
.navbar.main-navbar {
  position: sticky;
  top: 0;
  z-index: 999;
}

.nav-link.introjs-showElement {
  color: black !important;
}
.navbar-brand {
  font-size: 10pt;
}
.navbar {
  background-color: #0b6baf;
}
</style>
