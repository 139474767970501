import axios from 'axios';

export async function getPlans() {
  const response = await axios.get('/plans');
  return response.data;
}

export async function getPlan(planId) {
  const response = await axios.get(`/plans/${planId}`);
  return response.data;
}
